import { useDispatch, useSelector } from 'react-redux';
import { switchLanguages } from 'store/ducks/layout.js';
import './index.scss';

const LanguageSwitcher = () => {
    const dispatch = useDispatch();
    const lang = useSelector(state => state.layout.lang);

    const LANGUAGES = [
        { value: 'en', label: '🇬🇧 English' },
        { value: 'fi', label: '🇫🇮 Suomi' },
        { value: 'se', label: '🇸🇪 Svenska' },
    ];

    const onChange = e => dispatch(switchLanguages(e.target.value));

    return (
        <select className='language-switcher' onChange={onChange} value={lang}>
            {LANGUAGES.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
        </select>
    );
};

export default LanguageSwitcher;
