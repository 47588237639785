import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { addLocale } from 'ttag';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import store from './store/createStore.js';
import createServiceWorker from './sw/createSw.js';
import PPBRouter from './routes/PPBRouter.js';
import { getLocale, setLocale } from './utils.js';

addLocale('fi', require('./i18n/fi.po.json'));
addLocale('en', require('./i18n/en.po.json'));
addLocale('se', require('./i18n/se.po.json'));

const locale = getLocale();
setLocale(locale.slice(0, 2), true);

createServiceWorker(store);

polyfillCountryFlagEmojis();

const App = () => (
    <Provider store={store}>
        <PPBRouter />
    </Provider>
);

const rootElement = document.getElementById('root');
if(rootElement.hasChildNodes()) hydrate(<App />, rootElement);
else render(<App />, rootElement);

export { App };
