import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setLocale, getLocale } from 'utils.js';

const initialState = {
    loading: false,
    topNotification: { visible: false, text: null, type: null, style: null },
    lang: getLocale(),
};

let hTimeoutResetNotification;
export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setLoading: (state, { payload }) => { state.loading = payload; },
        showTopNotification: (state, { payload }) => {
            const { topNotification } = state;

            const {
                text = topNotification.text,
                type = topNotification.type ?? 'error',
                style = topNotification.style,
            } = payload;

            state.topNotification = { visible: true, text, type, style };
        },
        hideTopNotification: state => {
            clearTimeout(hTimeoutResetNotification);
            state.topNotification = initialState.topNotification;
        },
        backendIsNotDone: (state, { payload }) => {
            const { text, notificationType = 'error' } = payload;
            state.topNotification.text = text;
            state.topNotification.type = notificationType;
        },
        switchLanguages: (state, { payload }) => {
            setLocale(payload, true);
            state.lang = payload;
        },
    },
});

export const { setLoading, hideTopNotification, backendIsNotDone, switchLanguages } = layoutSlice.actions;

// Use this instead of `showTopNotification` directly, because this thunk contains the hiding action as well
export const notify = createAsyncThunk('layout/showAndHideTopNotification', (args, { dispatch }) => {
    dispatch(layoutSlice.actions.showTopNotification(args));
    hTimeoutResetNotification = setTimeout(() => dispatch(hideTopNotification()), 2 ** 12);
});

export default layoutSlice.reducer;
