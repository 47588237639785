import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'ttag';
import { Input } from 'components/Input/index.js';
import { dismissLoginModal } from 'store/ducks/auth.js';
import { useLoginMutation } from 'api/index.js';
import './index.scss';

const LoginModal = ({ type }) => {
    const dispatch = useDispatch();
    const [login] = useLoginMutation();
    const navigate = useNavigate();
    const [payload, setPayload] = useState({ username: '', password: '' });

    const FIELDS = [
        { name: 'username', value: '', label: t`(FORM_FIELD_LABEL)_USERNAME`, autoFocus: true },
        { name: 'password', value: '', label: t`(FORM_FIELD_LABEL)_PASSWORD`, type: 'password' },
    ];

    const onSubmit = e => {
        e.preventDefault();

        login(payload, type)
            .then(() => window.location.reload())
            .catch(e => e);
    };

    const onCancel = () => {
        dispatch(dismissLoginModal());
        navigate('/');
    };

    const onChange = e => setPayload({ ...payload, [e.target.name]: e.target.value });

    return (
        <div className='login-modal-wrap'>
            <form className='login-modal' onSubmit={onSubmit}>
                <h2>{t`LOGIN_MODAL_TITLE`}</h2>
                <h3>{t`LOGIN_MODAL_SUBTITLE`}</h3>
                {FIELDS.map((field, i) => <Input key={i} {...field} onChange={onChange} value={payload[field.name]} />)}
                <input type='submit' className='button--green' value={t`LOGIN`} />
                <button type='button' onClick={onCancel}>{t`CANCEL`}</button>
            </form>
        </div>
    );
};

export default LoginModal;
