import { lazy } from 'react';
import { t } from 'ttag';
import { APP_NAME } from 'constants.js';

const isOperator = APP_NAME === 'OPERATOR';
const isPerson = APP_NAME === 'PERSON';
// const isBoth = !isOperator && !isPerson;

// All routes that do not require the user to be logged in to be accessed

// DO NOT IMPORT THESE IN ANY VIEWS. Use our own hooks, or ones from `react-router-dom` to do whatever you need.
/*
    'pathpattern': { // The URL path that some view should be rendered at
        element: Element, // The rendered view component. YOU MOST LIKELY WANT TO `lazy(() => import(''))` THIS
        getTitle: Function, // Function that returns a `t` -string
        ...rest // Any other values can be added, and are accessible in any components with the `useRouteHandle` -hook
    }
*/

const UnauthenticatedRoutes = {
    '/': {
        element: lazy(() => import('views/ChooseApp/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_CHOOSE_APP`,
    },
    '/telia-login': {
        element: lazy(() => import('views/person/Login/TeliaReturnPage/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_LOGIN_TELIA`,
    },
    '/login/person': {
        element: lazy(() => import('views/person/Login/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_LOGIN_PERSON`,
    },
    '/login/dev': {
        element: lazy(() => import('views/person/DevLogin/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_LOGIN_DEV`,
    },
    '/login/operator': {
        element: lazy(() => import('views/operator/Login/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_LOGIN_OPERATOR`,
    },
    '/logout': {
        element: lazy(() => import('views/Logout/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_LOGOUT`,
    },
    '/reset/:token': {
        element: lazy(() => import('views/PasswordReset/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PASSWORD_RESET`,
    },
    '/forms/battery': {
        element: lazy(() => import('views/Forms/Battery/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_UNAUTHENTICATED_BATTERY_FORM`,
    },
    '*': {
        element: lazy(() => import('views/NotFound/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_NOT_FOUND`,
    },
};

isOperator && (UnauthenticatedRoutes['/'] = UnauthenticatedRoutes['/login/operator']);
isPerson && (UnauthenticatedRoutes['/'] = UnauthenticatedRoutes['/login/person']);

export default UnauthenticatedRoutes;
